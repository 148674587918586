import axios from "axios";
import { request } from "@/utils/request";
// 查询数据
export function selectLove(data) {
  return request({
    url: `/admin/love/select`,
    method: "POST",
    data,
  });
}

// 新增数据
export function addLove(data) {
  return request({
    url: `/admin/love/add`,
    method: "POST",
    data,
  });
}

/* 修改数据 */
export function updateLove(data) {
  return request({
    url: `/admin/love/update`,
    method: "POST",
    data,
  });
}

// 删除数据
export function deleteLove(data) {
  return request({
    url: `/admin/love/delete`,
    method: "POST",
    data,
  });
}
