<template>
  <div class="diseaseList">
    <!-- 筛选查询框 @change="selectGoodsByIdBtn"-->
    <el-form :inline="true">
      <!-- <el-button type="primary" size="small" v-waves @click="handleAdd"
        >新增</el-button
      > -->
      <el-form-item label="收藏类型:" style="margin:0 20px" size="small">
        <el-select
          v-model="listQuery.type"
          placeholder="请选择"
          style="width: 100%;"
          clearable
        >
          <div v-for="(item, index) in typeList" :key="index">
            <el-option :label="item.name" :value="item.id"></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item label="收藏名称: " style="margin:0 20px" size="small">
        <el-input
          v-model="listQuery.name"
          placeholder="请输入收藏名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-button
        class="addBtn"
        type="primary"
        @click="getListData"
        size="small"
        v-waves
        >搜索</el-button
      >
    </el-form>
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <!-- <template slot="store_id" slot-scope="{ row }">
          <StoreToString :store_id="row.store_id" />
        </template> -->
        <!-- <template slot="img" slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.img"
            :preview-src-list="[row.img]"
          >
          </el-image>
        </template> -->

        <template slot="type" slot-scope="{ row }">
          <el-tag
            type="success"
            effect="dark"
            size="mini"
            v-if="row.type === 1"
          >
            收藏作者
          </el-tag>
          <el-tag effect="dark" size="mini" v-else-if="row.type === 3">
            收藏商品
          </el-tag>
          <el-tag
            type="success"
            effect="dark"
            size="mini"
            v-else-if="row.type === 4"
          >
            收藏艺术家
          </el-tag>
          <el-tag type="danger" effect="dark" size="mini" v-else>
            收藏文章
          </el-tag>
        </template>
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <!-- <el-button size="mini" type="text" @click="handleEdit(row)" primary
              >编辑</el-button
            > -->
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>

      <!-- 产品提交表单 -->
      <el-dialog
        :title="inpitTypeName"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="90px">
          <!-- <el-form-item label="所属门店:" required>
            <el-select
              v-model="dialogForm.store_id"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <div v-for="(item, index) in filterStoreList" :key="index">
                <el-option
                  :label="item.store_name"
                  :value="item.store_id"
                ></el-option>
              </div>
            </el-select>
          </el-form-item> -->
          <el-form-item label="文章名称" required>
            <el-input v-model="dialogForm.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="描述文字" required>
            <el-input v-model="dialogForm.detail" clearable></el-input>
          </el-form-item>

          <el-form-item label="文章链接" required>
            <el-input v-model="dialogForm.link" clearable></el-input>
          </el-form-item>
          <el-form-item label="收藏类型: ">
            <el-select
              v-model="dialogForm.type"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <el-option
                v-for="item in typeList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文章封面" width="100" required>
            <div class="demo-image__preview">
              <el-image
                style="width: 100px; height: 100px"
                :src="dialogForm.img"
                :preview-src-list="[dialogForm.img]"
              >
              </el-image>
            </div>
          </el-form-item>
          <el-form-item label="上传">
            <el-upload
              class="upload-demo"
              :action="action"
              :multiple="true"
              :on-success="handleSuccess_cover"
              :file-list="fileList"
              list-type="picture"
              name="formData"
            >
              <el-button size="small" type="primary"
                >上传文章主图</el-button
              >
              <div slot="tip" class="el-upload__tip">
                请上传jpg/png文件，500px X 500px, 会自动覆盖原来的图片
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="dialogFormVisible = false">取 消</el-button>
          <el-button class="addBtn" type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  selectLove,
  updateLove,
  deleteLove,
  addLove,
} from "@/services/articleManage/love";
import { IP } from "@/services/config.js";
import ElTable from "@/components/elTable/index.vue";
import StoreToString from "@/components/filters/storeToString.vue";
import listHeader from "./listHeader";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    StoreToString,
  },
  created() {
    this.getListData();
  },

  data() {
    return {
      loading: false,
      listQuery: {
        type: "",
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },

      goodsTitle: "",
      tableData: [],
      // 一级文章 二级文章 文章
      selectLove: "",
      select_two_id: "",
      typeList: [
        { id: 1, name: "收藏作者" },
        { id: 2, name: "文章" },
        { id: 3, name: "商品" },
        { id: 4, name: "收藏艺术家" },
      ],
      categoryArr_one: [], //遍历一级文章
      parentId: "", //增加二级文章时选择的一级文章id
      isaddLoveOne: 1, //增加一级文章还是二级文章
      dataList: [],
      goodsArr: [],
      dialogFormVisible: false,
      inpitTypeName: "",
      formLabelWidth: "120px",
      // action: "http://127.0.0.1:6688/uploadFile/add",
      action: IP + "/uploadFile/add",
      fileList: [],
      pageCount: "",
      pageSize: 8,
      dialogForm: { store_id: "" },
      shuf: [],
      cover: [],
      describe: [],
    };
  },
  computed: {
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      // listQuery.store_id =
      //   listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectLove(listQuery);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    handleAdd(type) {
      this.dialogFormVisible = true;
      this.inpitTypeName = "新增文章";
      this.fileList = [];
      this.dialogForm = {};
      this.isaddLoveOne = 1;
    },

    /* 修改 */
    handleEdit(row) {
      this.fileList = [];
      this.dialogFormVisible = true;
      this.inpitTypeName = "修改文章";
      this.dialogForm = JSON.parse(JSON.stringify(row));
      // if (this.dialogForm.store_id) {
      //   this.dialogForm.store_id = this.dialogForm.store_id?.split(",");
      // }
    },

    handleSuccess_cover(e) {
      this.dialogForm.img = e.data;
    },

    /* 保存 */
    save() {
      //必填字段校验
      if (
        !this.dialogForm.name ||
        !this.dialogForm.link ||
        !this.dialogForm.detail ||
        !this.dialogForm.img
      ) {
        this.$message.error("请填写必填字段");
        return;
      }
      // if (this.dialogForm.store_id.length > 0) {
      //   console.log("this.dialogForm.store_id=====", this.dialogForm.store_id);
      //   this.dialogForm.store_id = this.dialogForm.store_id.join(",");
      // }
      if (this.inpitTypeName == "新增文章") {
        addLove({ ...this.dialogForm, type: 1 }).then((res) => {
          console.log(res);
          if (res.code) {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
      if (this.inpitTypeName == "修改文章") {
        updateLove(this.dialogForm).then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm(`此操作将永久删除收藏'${row.name}'吗, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteLove({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>


.diseaseList {
  width: 100%;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  /* max-width: 1405px; */
  margin: 10px auto;
}

.goodsTitle:hover {
  cursor: pointer;
}
.upload-demo {
  margin: 20px;
}
</style>
