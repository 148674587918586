export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    { label: "类型", prop: "type", slots: "type", width: 200 },
    { label: "内容名称", prop: "name", width: 350},
    // { label: "描述", prop: "detail", width: 200 },
    { label: "收藏关联ID", prop: "loveId", width: 150 },
    // { label: "收藏者ID", prop: "accountID", width: 150 },
    // { label: "所属门店", prop: "store_id", slots: "store_id", hidden: true },
    {
      label: "创建时间",
      prop: "createTime",
      width: 200,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 200,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
