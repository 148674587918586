<!-- 0待填报 1已填报 -->
<template>
  <div class="statusBox">
    <!-- "——" -->
    {{ storeToString }}
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StoreToString",
  components: {},
  props: {
    store_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["filterStoreList"]),
    storeToString() {
      const storeIdList = this.store_id?.split(",");
      const result = storeIdList.map((item) => {
        const storeName = this.filterStoreList.find((value) => {
          return item == value.store_id;
        })?.store_name;
        return storeName || '暂无信息';
      });
      return result.join(", ");
    },
  },
  methods: {},
};
</script>

<style scoped lang="less"></style>
